import React, { useState } from 'react';

function Header({ isAuth, onAuthClick, onCabinetClick, balance }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="header-glass">
      <div className="header-content">
        <a href="/" className="logo">
          yafree
        </a>
        
        <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <a href="/" className="nav-link active">Главная</a>
          {isAuth && (
            <button 
              className="wallet-button"
              onClick={() => {
                onCabinetClick();
                setIsMenuOpen(false);
              }}
            >
              <span className="wallet-icon">💰</span>
              <span className="wallet-balance">{balance?.toLocaleString()} ₽</span>
            </button>
          )}
          <button 
            className="auth-button"
            onClick={() => {
              onAuthClick();
              setIsMenuOpen(false);
            }}
          >
            {isAuth ? 'Выйти' : 'Войти'}
          </button>
        </nav>
      </div>
    </header>
  );
}

export default Header; 