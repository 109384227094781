import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info">
          <div className="company-details">
            <p>ИНН: 667115892481</p>
          </div>
          
          <div className="footer-links">
            <Link to="/privacy">Политика конфиденциальности</Link>
            <Link to="/terms">Пользовательское соглашение</Link>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p className="copyright">
            © {new Date().getFullYear()} YaFree. Все права защищены
          </p>
          <p className="legal-notice">
            Сайт не является публичной офертой
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 