import React, { useState } from 'react';
import ticket200 from '../images/ticket-200.jpg';
import ticket500 from '../images/ticket-500.jpg';
import ticket1000 from '../images/ticket-1000.jpg';
import '../styles/TicketList.css';

const TICKETS = [
  { 
    id: 1, 
    title: 'Картина 200',
    price: 200,
    priceDisplay: '200,00 ₽',
    image: ticket200
  },
  { 
    id: 2, 
    title: 'Картина 500',
    price: 500,
    priceDisplay: '500,00 ₽',
    image: ticket500
  },
  { 
    id: 3, 
    title: 'Картина 1000',
    price: 1000,
    priceDisplay: '1000,00 ₽',
    image: ticket1000
  }
];

function TicketList() {
  const [loading, setLoading] = useState(false);

  const handleBuyTicket = async (ticket) => {
    try {
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      window.location.href = 'https://securepay.tinkoff.ru/html/payForm/fail.html';
      
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tickets-container">
      {TICKETS.map(ticket => (
        <div key={ticket.id} className="ticket-card">
          <div className="ticket-image-container">
            <img src={ticket.image} alt={ticket.title} className="ticket-image" />
            <div className="ticket-overlay">
              <span className="ticket-category">Картина</span>
            </div>
          </div>
          
          <div className="ticket-info">
            <h3 className="ticket-title">{ticket.title}</h3>
            <div className="ticket-meta">
              <span className="ticket-price">{ticket.priceDisplay}</span>
              <button 
                className={`buy-button ${loading ? 'loading' : ''}`}
                onClick={() => handleBuyTicket(ticket)}
                disabled={loading}
              >
                {loading ? 'Загрузка...' : (
                  <>
                    <span className="button-text">Купить</span>
                    <span className="button-icon">→</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TicketList;