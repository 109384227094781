import React from 'react';
import '../styles/Terms.css';

function Terms() {
  return (
    <div className="terms-container">
      <div className="terms-content">
        <h1>Пользовательское соглашение</h1>
        
        <p className="terms-intro">
          Настоящее Пользовательское соглашение (далее — «Соглашение») регулирует отношения между владельцем сайта yafree.ru (далее — «Компания») и пользователями сайта (далее — «Пользователь») по поводу приобретения картин на сайте yafree.ru (далее — «Сайт»). Используя Сайт, Пользователь соглашается с условиями данного Соглашения.
        </p>

        <section>
          <h2>1. Общие положения</h2>
          <p>1.1. Настоящее Соглашение является публичной офертой и регулирует порядок использования Сайта и приобретения картин.</p>
          <p>1.2. Пользователь обязан внимательно ознакомиться с условиями данного Соглашения перед началом использования Сайта. Продолжая пользоваться Сайтом, Пользователь подтверждает свое согласие с условиями Соглашения.</p>
          <p>1.3. Компания оставляет за собой право вносить изменения в настоящее Соглашение в любое время без предварительного уведомления Пользователя. Изменения вступают в силу с момента их публикации на Сайте.</p>
        </section>

        <section>
          <h2>2. Услуги Сайта</h2>
          <p>2.1. Сайт yafree.ru предоставляет Пользователю возможность приобретать картины (далее — «Товары») в электронном виде для участия в программе, где каждая третья покупка вознаграждается удвоением стоимости товара.</p>
          <p>2.2. Компания не гарантирует возврат средств за каждую покупку, однако, согласно условиям программы, каждая третья покупка по статистике может возвращаться Пользователю в виде удвоенной суммы стоимости купленного товара.</p>
          <p>2.3. Условия возврата удвоенной стоимости товара:</p>
          <ul>
            <li>Каждая третья покупка на Сайте может быть вознаграждена удвоенной стоимостью.</li>
            <li>Результаты такой покупки фиксируются системой автоматически, и в случае выигрыша Пользователь получает удвоенную стоимость товара на свой баланс на Сайте или другим способом, оговоренным Компанией.</li>
          </ul>
        </section>

        <section>
          <h2>3. Права и обязанности Пользователя</h2>
          <p>3.1. Пользователь имеет право:</p>
          <ul>
            <li>Приобретать картины на Сайте yafree.ru;</li>
            <li>Получать возврат удвоенной стоимости товара при выигрыше согласно условиям программы;</li>
            <li>Обратиться в службу поддержки для разъяснений по вопросам, связанным с покупкой и условиями программы.</li>
          </ul>
          <p>3.2. Пользователь обязуется:</p>
          <ul>
            <li>Предоставлять достоверные данные при регистрации и покупке;</li>
            <li>Использовать Сайт исключительно в соответствии с условиями данного Соглашения;</li>
            <li>Не нарушать права и интересы других Пользователей и третьих лиц.</li>
          </ul>
        </section>

        <section>
          <h2>4. Права и обязанности Компании</h2>
          <p>4.1. Компания имеет право:</p>
          <ul>
            <li>Изменять условия работы Сайта, его структуру и контент в любое время;</li>
            <li>Приостанавливать или прекращать работу Сайта yafree.ru в случае необходимости проведения технических работ;</li>
            <li>Аннулировать покупки Пользователей в случае подозрения на мошеннические действия.</li>
          </ul>
          <p>4.2. Компания обязуется:</p>
          <ul>
            <li>Обеспечить доступность Сайта для Пользователей, за исключением случаев технических перерывов или форс-мажорных обстоятельств;</li>
            <li>Производить возврат удвоенной стоимости товара в случаях, оговоренных данным Соглашением;</li>
            <li>Оперативно обрабатывать запросы Пользователей по поводу покупок и работы Сайта.</li>
          </ul>
        </section>

        <section>
          <h2>5. Ответственность сторон</h2>
          <p>5.1. Компания не несет ответственности за любой ущерб, который может быть причинен Пользователю в результате использования Сайта yafree.ru.</p>
          <p>5.2. Пользователь несет полную ответственность за соблюдение законодательства при использовании Сайта, включая правила покупки картин.</p>
          <p>5.3. В случае нарушения условий настоящего Соглашения Пользователем, Компания имеет право приостановить или прекратить доступ Пользователя к Сайту.</p>
        </section>

        <section>
          <h2>6. Политика возврата</h2>
          <p>6.1. Возврат средств за приобретенные картины возможен только в случаях, предусмотренных законодательством и настоящим Соглашением.</p>
          <p>6.2. Возврат удвоенной стоимости осуществляется автоматически на баланс Пользователя в случае успешного выполнения условий программы.</p>
        </section>

        <section>
          <h2>7. Конфиденциальность</h2>
          <p>7.1. Все персональные данные Пользователя, предоставленные при регистрации и покупке, обрабатываются в соответствии с Политикой конфиденциальности Сайта yafree.ru.</p>
          <p>7.2. Компания обязуется не передавать персональные данные Пользователей третьим лицам без их согласия, за исключением случаев, предусмотренных законодательством.</p>
        </section>

        <section>
          <h2>8. Заключительные положения</h2>
          <p>8.1. Настоящее Соглашение регулируется законодательством Российской Федерации.</p>
          <p>8.2. Все споры, возникающие в связи с выполнением настоящего Соглашения, решаются путем переговоров, а в случае невозможности достижения согласия, передаются на рассмотрение в судебные органы Российской Федерации.</p>
        </section>

        <section className="terms-footer">
          <p>Использование Сайта yafree.ru означает согласие Пользователя с условиями данного Соглашения.</p>
        </section>
      </div>
    </div>
  );
}

export default Terms; 