import React, { useState } from 'react';
import PaymentForm from './PaymentForm';
import Wallet from './Wallet';

function Cabinet({ initialTab = 'wallet', balance, onBalanceChange }) {
  const [activeTab, setActiveTab] = useState(initialTab);

  return (
    <div className="cabinet">
      <div className="cabinet-tabs">
        <button 
          className={`tab-button ${activeTab === 'wallet' ? 'active' : ''}`}
          onClick={() => setActiveTab('wallet')}
        >
          Кошелек
        </button>
        <button 
          className={`tab-button ${activeTab === 'payments' ? 'active' : ''}`}
          onClick={() => setActiveTab('payments')}
        >
          Вывод средств
        </button>
        <button 
          className={`tab-button ${activeTab === 'history' ? 'active' : ''}`}
          onClick={() => setActiveTab('history')}
        >
          История
        </button>
      </div>

      <div className="cabinet-content">
        {activeTab === 'wallet' && (
          <div className="wallet-section">
            <Wallet balance={balance} />
            
            <div className="recent-transactions">
              <h4>Последние операции</h4>
              {/* Здесь будет список последних операций */}
              <div className="no-transactions">
                Операций пока нет
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'payments' && (
          <div className="payments-section">
            <Wallet balance={balance} />
            <PaymentForm 
              maxAmount={balance}
              onSuccess={(amount) => onBalanceChange(prev => prev - amount)}
            />
          </div>
        )}

        {activeTab === 'history' && (
          <div className="history-section">
            <h3>История операций</h3>
            {/* Здесь будет компонент с историей операций */}
            <div className="no-history">
              История операций пока пуста
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Cabinet; 