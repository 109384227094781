import React from 'react';

function Wallet({ balance }) {
  return (
    <div className="wallet-card">
      <div className="wallet-header">
        <h3>Ваш баланс</h3>
        <div className="wallet-balance">
          <span className="balance-amount">{balance.toLocaleString()}</span>
          <span className="balance-currency">₽</span>
        </div>
      </div>
      
      <div className="wallet-info">
        <div className="info-item">
          <span className="info-label">Доступно для вывода</span>
          <span className="info-value">{balance.toLocaleString()} ₽</span>
        </div>
        <div className="info-item">
          <span className="info-label">Минимальная сумма вывода</span>
          <span className="info-value">100 ₽</span>
        </div>
      </div>
    </div>
  );
}

export default Wallet; 