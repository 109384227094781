const API_URL = 'https://secured-openapi.tbank.ru/api/v1/payment';

export const paymentService = {
  // Создание выплаты
  createPayment: async (amount, userAccount) => {
    try {
      const response = await fetch(`${API_URL}/ruble-transfer/pay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Здесь должен быть ваш API ключ
          'Authorization': 'Bearer YOUR_API_KEY'
        },
        body: JSON.stringify({
          id: Date.now().toString(), // Уникальный ID транзакции
          from: {
            accountNumber: "YOUR_COMPANY_ACCOUNT" // Номер счета компании
          },
          to: {
            accountNumber: userAccount
          },
          purpose: "Выплата выигрыша YaFree",
          amount: amount,
          documentNumber: Date.now().toString().slice(-3),
          executionOrder: 5
        })
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка при создании выплаты:', error);
      throw error;
    }
  },

  // Проверка статуса выплаты
  checkPaymentStatus: async (paymentId) => {
    try {
      const response = await fetch(`${API_URL}/status/${paymentId}`, {
        headers: {
          'Authorization': 'Bearer YOUR_API_KEY'
        }
      });
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка при проверке статуса:', error);
      throw error;
    }
  }
}; 