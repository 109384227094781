import React, { useState } from 'react';
import { paymentService } from '../services/paymentService';

function PaymentForm() {
  const [cardNumber, setCardNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  // Форматирование номера карты (XXXX XXXX XXXX XXXX)
  const formatCardNumber = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  // Обработка ввода номера карты
  const handleCardNumberChange = (e) => {
    const formatted = formatCardNumber(e.target.value);
    setCardNumber(formatted);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Валидация номера карты
    const cleanCardNumber = cardNumber.replace(/\s/g, '');
    if (cleanCardNumber.length !== 16) {
      setStatus({
        type: 'error',
        message: 'Введите корректный номер карты'
      });
      return;
    }

    setLoading(true);
    try {
      const payment = await paymentService.createPayment(
        Number(amount), 
        cleanCardNumber
      );
      
      setStatus({
        type: 'success',
        message: 'Выплата успешно создана и будет обработана в течение 24 часов'
      });

      // Очищаем форму после успешной отправки
      setCardNumber('');
      setAmount('');

    } catch (error) {
      setStatus({
        type: 'error',
        message: 'Ошибка при создании выплаты'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-form">
      <h3>Заказать выплату</h3>
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Номер карты</label>
          <input
            type="text"
            value={cardNumber}
            onChange={handleCardNumberChange}
            placeholder="XXXX XXXX XXXX XXXX"
            maxLength="19" // 16 цифр + 3 пробела
            required
            className="card-input"
          />
        </div>

        <div className="form-group">
          <label>Сумма выплаты</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Введите сумму"
            required
            min="100"
            max="100000"
            className="amount-input"
          />
          <span className="input-hint">От 100 до 100 000 ₽</span>
        </div>

        <button 
          type="submit" 
          className="submit-button"
          disabled={loading}
        >
          {loading ? 'Обработка...' : 'Заказать выплату'}
        </button>
      </form>

      {status && (
        <div className={`status-message ${status.type}`}>
          {status.message}
        </div>
      )}
    </div>
  );
}

export default PaymentForm; 